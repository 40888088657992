@import url(https://fonts.googleapis.com/css2?family=Piazzolla:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oregano&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;700&display=swap);
html{scroll-behavior:smooth}body{font-family:'Neue Haas Pro', sans-serif;color:inherit;background-color:#faf8f5}#page-wrap{min-height:calc(100vh - 340px)}.container:nth-child(odd){background-color:#faf8f5}.container:nth-child(even){background-color:white}h1,h2,h3,h4,h5,h6{font-family:"Piazzolla", sans-serif;margin:0;padding:0}.content p,.content li{font-size:1.1em;line-height:1.7}.markdown img{width:100%}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: 300;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayLight.646ed20d.woff) format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: 300;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayLightItalic.a86ac02d.woff) format('woff');
}

@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: 200;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayThin.dda1d873.woff) format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: 200;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayThinItalic.e67256ad.woff) format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayRoman.bf8caa25.woff) format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: normal;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayRomanItalic.8e81f54f.woff) format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: 500;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayMediu.f832a6de.woff) format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: 500;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayMediumItalic.6413cf1b.woff) format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Neue Haas Grotesk Display Pro 75 Bold'), url(/static/media/NeueHaasDisplayBold.385a203e.woff) format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: 700;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayBoldItalic.ef6ab007.woff) format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: 900;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayBlack.eddf2ac3.woff) format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: 900;
    src: local('Neue Haas Pro'), url(/static/media/NeueHaasDisplayBlackItalic.7d7b1563.woff) format('woff');
}

