/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: 300;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayLight.woff') format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: 300;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayLightItalic.woff') format('woff');
}

@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: 200;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayThin.woff') format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: 200;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayThinItalic.woff') format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayRoman.woff') format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: normal;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayRomanItalic.woff') format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: 500;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayMediu.woff') format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: 500;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayMediumItalic.woff') format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Neue Haas Grotesk Display Pro 75 Bold'), url('NeueHaasDisplayBold.woff') format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: 700;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayBoldItalic.woff') format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: normal;
    font-weight: 900;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayBlack.woff') format('woff');
}


@font-face {
    font-family: 'Neue Haas Pro';
    font-style: italic;
    font-weight: 900;
    src: local('Neue Haas Pro'), url('NeueHaasDisplayBlackItalic.woff') format('woff');
}
