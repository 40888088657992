@import url('https://fonts.googleapis.com/css2?family=Piazzolla:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oregano&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;700&display=swap');


html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Neue Haas Pro', sans-serif;
  color: inherit;
  background-color: rgb(250, 248, 245);
}

#page-wrap {
  min-height: calc(100vh - 340px);
}

.container:nth-child(odd) {
  background-color: rgb(250, 248, 245);
}

.container:nth-child(even) {
  background-color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Piazzolla", sans-serif;
  margin: 0;
  padding: 0;
}

.content p, .content li {
  font-size: 1.1em;
  line-height: 1.7;
}

.markdown img {
  width: 100%;
}
